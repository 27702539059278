export const fontSizes = {
   xxxs: '0.70rem', // 11.2px
   xxs: '0.75rem', // 12px
   xs: '0.875rem', // 14px
   sm: '0.93rem', // 15px
   md: '1rem', // 16px
   lg: '1.125rem', // 18px
   xl: '1.25rem', // 20px
   '2xl': '1.5rem', // 24px
   '3xl': '1.625rem', // 26px
   '4xl': '2rem', // 32px
   '5xl': '3.25rem', // 52px
};

export const fonts = {
   heading: `'Cannon', sans-serif`,
   body: `'TTCommonsPro', sans-serif`,
};
