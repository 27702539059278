import { type SystemStyleObject } from '@chakra-ui/react';

const ZendeskStyle: SystemStyleObject = {
   'body.is-zendesk-opened div div div[role=presentation]': {
      pointerEvents: 'auto',
   },

   'body.is-zendesk-opened': {
      '#main-nav': {
         zIndex: 98,
      },
   },

   '@media screen and (max-width: 1024px)': {
      'body #home': {
         width: '100% !important',
      },
   },

   'body iframe[title="Messaging window"]': {
      borderRadius: {
         base: '0 !important',
         lg: '16px !important',
      },
      overflow: 'hidden !important',
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity 0.3s ease-in-out',
      width: {
         base: '100vw !important',
         lg: 'var(--chat-width) !important',
      },
      right: {
         base: '0 !important',
         lg: 'var(--chat-offset) !important',
      },
      bottom: {
         base: '0 !important',
         lg: 'var(--chat-offset) !important',
      },
      position: 'fixed !important',

      height: {
         base: 'var(--app-height) !important',
         lg: 'var(--chat-height) !important',
      },
      maxHeight: {
         base: 'var(--app-height) !important',
         lg: '95dvh !important',
      },
   },

   'body.is-zendesk-opened iframe[title="Messaging window"]': {
      boxShadow: '0 0 7px rgba(0, 0, 0, .2) !important',
      pointerEvents: 'auto',
      opacity: 1,
   },

   'body .zendesk-not-allowed': {
      position: 'fixed',
      bottom: {
         base: '0',
         lg: 'var(--chat-offset)',
      },
      right: {
         base: '0',
         lg: 'var(--chat-offset)',
      },
      width: {
         base: '100vw',
         lg: 'var(--chat-width)',
      },
      background: 'white',
      borderRadius: '16px',
      zIndex: 9999999,
      opacity: 0,
      pointerEvents: 'none',
      transition: 'height 0.30s ease-in-out, opacity 0.25s ease-in-out',
      transitionDelay: '0.05s',
      height: '0px',
   },

   'body.is-zendesk-opened .zendesk-not-allowed': {
      opacity: 1,
      pointerEvents: 'auto',
      transitionDelay: '0.08s',
      height: {
         base: '100px',
         lg: '90px',
      },
   },
};

export default ZendeskStyle;
