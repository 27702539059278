import { type ComponentStyleConfig } from '@chakra-ui/react';

const sizeFull = () => {
   return {
      dialog: {
         minHeight: 'full',
      },
   };
};

const sizeInside = () => {
   return {
      dialogContainer: {
         padding: 4,
      },
      dialog: {
         maxW: '530px',
         minW: {
            base: '100%',
            lg: '530px',
         },
         alignItems: 'center',
         justifyContent: 'center',

         py: {
            base: 4,
            lg: 6,
         },
         px: {
            base: 4,
            lg: 16,
         },
      },
   };
};

const sizeInsideSmall = () => {
   return {
      dialogContainer: {
         padding: 4,
      },
      dialog: {
         maxW: '380px',
         minW: {
            base: '100%',
            lg: '380px',
         },
         alignItems: 'center',
         justifyContent: 'center',

         py: {
            base: 4,
            lg: 6,
         },
         px: {
            base: 4,
            lg: 10,
         },
      },
   };
};

const sizes = {
   inside: sizeInside,
   insideSmall: sizeInsideSmall,
   full: sizeFull,
};

const Modal: ComponentStyleConfig = {
   sizes,
   baseStyle: {
      dialogContainer: {
         height: 'var(--app-height) !important',
      },
   },
};

export default Modal;
