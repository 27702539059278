import { type ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
   variants: {},
   baseStyle: {
      // @TODO: Check checkbox after chakra update
      container: {
         opacity: 0.9,
         transition: 'all .3s ease',
      },

      control: {
         borderRadius: '6px',
         bg: 'transparent',
         borderWidth: '2px',
         borderColor: 'text.primary',
         opacity: 0.3,
         transition: 'all .3s ease',
         mr: 1,

         _focusVisible: {
            boxShadow: '0 0 0 4px rgba(18, 23, 35, 0.25)',
         },

         _invalid: {
            borderColor: 'alert.primary',
         },

         _checked: {
            bg: 'background.primary',
            opacity: 1,
            color: 'black',
            borderColor: 'white',

            _hover: {
               boxShadow: 'none',
               bg: 'background.primary',
               borderColor: 'white',
            },
         },
      },
   },
   defaultProps: {
      size: 'xl',
   },
   sizes: {
      xl: {
         control: {
            w: '25px',
            h: '25px',
         },
      },
   },
};

export default Checkbox;
