import { type ComponentStyleConfig } from '@chakra-ui/react';

import form from './form';

const FormError: ComponentStyleConfig = {
   baseStyle: {
      text: form.baseStyle.helperText,
   },
};

export default FormError;
