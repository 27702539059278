import { extendTheme, type theme } from '@chakra-ui/react';

// Components
import * as components from './components';
// Foundations
import * as foundations from './foundations';

// Global styles
import { default as globalStyles } from './global-styles';
import { type Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
   global: {
      display: 'none',
      ...globalStyles,
   },
};

export const themeOverrides = {
   ...foundations,
   components: {
      ...components,
   },
   styles,
};

export const customTheme = extendTheme(themeOverrides) as typeof theme &
   typeof themeOverrides;
