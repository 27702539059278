const container = {
   xxs: '480px',
   xs: '560px',
   sm: '620px',
   md: '850px',
   formContainer: '444px',
   formContainerWide: '660px',
};

const sizes = {
   container,
   sideDrawer: '460px',
};

export default sizes;
