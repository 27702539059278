import { type ComponentStyleConfig } from '@chakra-ui/react';

const sizeLeadtitle = {
   fontSize: {
      base: 'xl',
      lg: '2xl',
   },
   lineHeight: {
      base: 1.25,
      lg: 1.35,
   },
};

const sizeLeadSmall = {
   fontSize: {
      base: 'xl',
      lg: '4xl',
   },
   lineHeight: {
      base: 1.25,
      lg: 1.35,
   },
};

const sizeEyebrow = {
   fontSize: 'xs',
   textTransform: 'uppercase',
   lineHeight: 1.5,
   fontFamily: 'heading',
};

const sizeLeadText = {
   fontSize: 'xl',
   lineHeight: 1.35,
};

const sizeBody = {
   fontWeight: 400,
   fontSize: {
      base: 'md',
      md: 'lg',
   },
   lineHeight: {
      base: 1.35,
      md: 1.35,
   },
};

const sizeParagraph = {
   fontSize: 'md',
   lineHeight: 1.4,
};

const sizeParagraphSmall = {
   fontWeight: 500,
   fontSize: {
      base: 'xxs',
      md: 'xs',
   },
   lineHeight: 1.4,
};

const sizeParagraphSmallest = {
   fontSize: 'xxs',
   lineHeight: 1.4,
   letterSpacing: '0.005em',
};

const sizeParagraphLarge = {
   fontSize: 'lg',
   lineHeight: 1.35,
   fontWeight: 400,
};

const sizeParagraphLargeUppercase = {
   fontSize: 'lg',
   textTransform: 'uppercase',
   lineHeight: 1.2,
   letterSpacing: '0.1em',
};
const sizeCaption = {
   fontSize: {
      base: 'xs',
      md: 'lg',
   },
   textTransform: 'uppercase',
   lineHeight: 1.5,
   letterSpacing: '0.3em',
   fontFamily: 'heading',
};
const sizeCaptionSmall = {
   fontSize: {
      base: 'xs',
   },
   textTransform: 'uppercase',
   lineHeight: 1.5,
   letterSpacing: '0.2em',
   fontFamily: 'heading',
};

const sizes = {
   leadTitle: sizeLeadtitle,
   leadText: sizeLeadText,
   leadSmall: sizeLeadSmall,
   body: sizeBody,
   paragraph: sizeParagraph,
   paragraphSmall: sizeParagraphSmall,
   paragraphSmallest: sizeParagraphSmallest,
   paragraphLarge: sizeParagraphLarge,
   paragraphLargeUppercase: sizeParagraphLargeUppercase,
   caption: sizeCaption,
   captionSmall: sizeCaptionSmall,
   eyebrow: sizeEyebrow,
};

const Text: ComponentStyleConfig = {
   sizes,
   baseStyle: {
      fontWeight: 400,
      fontFamily: 'body',
      fontSize: 'body',
   },
};

export default Text;
