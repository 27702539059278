import { type ComponentStyleConfig } from '@chakra-ui/react';

const sizeLarge = {
   lineHeight: 1.3,
   fontSize: {
      base: '4xl',
      md: '5xl',
   },
};

const sizeSmall = {
   lineHeight: {
      base: 1.2,
      md: 1.3,
   },
   fontSize: {
      base: '3xl',
      md: '4xl',
   },
};

const sizes = {
   large: sizeLarge,
   small: sizeSmall,
};

const Heading: ComponentStyleConfig = {
   sizes,
   baseStyle: {
      letterSpacing: '-0.01em',
      fontWeight: 500,
      fontFamily: 'heading',
   },
};

export default Heading;
