import { type ComponentStyleConfig } from '@chakra-ui/react';
import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantBordered: SystemStyleFunction = (_props) => {
   return {
      addon: {
         border: 'none',
         borderRadius: '12px',
      },
      field: {
         bg: 'transparent',
         border: '2px solid',
         borderColor: 'background.primary',
         borderRadius: '12px',
      },
   };
};

const variantOutline: SystemStyleFunction = (props) => {
   const { colorScheme = 'gray' } = props;

   return {
      addon: {
         border: 'none',
         borderRadius: '12px',
         bg: colorScheme === 'gray' ? '#F1F1F1' : 'white',
      },
      field: {
         color: 'text.primary',
         fontSize: 'md',
         bg: colorScheme === 'gray' ? 'form.default' : 'white',
         border: 'none',
         borderRadius: '12px',

         _hover: {
            background: 'form.hover',
         },

         _disabled: {
            opacity: 0.5,

            _hover: {
               bg: colorScheme === 'gray' ? 'form.default' : 'white',
            },
         },

         _focus: {
            boxShadow: 'none',
            border: 'none',
            background: 'form.hover',
         },
         _invalid: {
            boxShadow: 'none',
            background: 'alert.secondary',
            _hover: {
               background: 'form.hover',
            },
         },

         _autofill: {
            _autofill: {
               boxShadow: '0 0 0px 1000px white inset',
            },
         },

         _placeholder: {
            color: 'text.primary',
            opacity: 0.5,
         },
      },
   };
};

const variants = {
   outline: variantOutline,
   bordered: variantBordered,
};

const Input: ComponentStyleConfig = {
   variants,
   sizes: {
      md: {
         field: {
            minHeight: '50px',
         },
      },
   },
};

export default Input;
