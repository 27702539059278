import SendbirdStyle from './sendbird';
import ZendeskStyle from './zendesk';

 
export default {
   body: {
      '--chat-width': '420px',
      '--chat-height': '700px',
      '--chat-offset': '15px',
      '--app-height': '100vh',
      '@supports (height: 100dvh)': {
         '--app-height': '100dvh',
      },
      '.async-hyde': {
         opacity: '0 !important',
      },
   },
   ...ZendeskStyle,
   ...SendbirdStyle,
};
