const Form = {
   baseStyle: {
      helperText: {
         color: 'white',
         fontSize: 'xxs',
         fontWeight: 400,
         letterSpacing: '0.005em',
         opacity: 0.85,
         marginTop: 2,
      },
      requiredIndicator: {
         color: 'alert.primary',
         marginLeft: '0.1em',
      },
   },
};

export default Form;
