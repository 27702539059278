import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         staleTime: 0,
         refetchOnWindowFocus: false,
         refetchInterval: 1000 * 60 * 5, // 5 minutes,
         retry: (failureCount, error) => {
            if (error instanceof AxiosError && error.status === 401) {
               return false;
            }

            return failureCount < 3;
         },
      },
   },
});
