import { type ComponentStyleConfig } from '@chakra-ui/react';
import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantBordered: SystemStyleFunction = (_props) => {
   return {
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'background.primary',
      borderRadius: '12px',
   };
};

const variants = {
   bordered: variantBordered,
};

const Textarea: ComponentStyleConfig = {
   variants,
   sizes: {
      md: {
         field: {
            minHeight: '150px',
         },
      },
   },
};

export default Textarea;
